import { get, post, put, Delete } from "@/api/axios";

// 财务白名单设置-列表
export const whiteList = (params) => get("/web/finance/white/list", params);

// 财务白名单设置-区域-列表
export const regionList = (params) =>
  get("/web/finance/white/region/list/" + params.id, "");

// 财务白名单设置-会员-列表
export const userList = (params) => get("/web/finance/white/user/list", params);

// 财务白名单设置-新增-区域-列表
export const addRegionList = (params) =>
  get("/web/finance/white/add/region/list", params);

// 财务白名单设置-新增
export const whiteAdd = (params) => post("/web/finance/white/add", params);

// 财务白名单设置-删除
export const deleteWhite = (params) =>
  Delete("/web/finance/white/delete/" + params.id, "");

// 白名单设置-查看
export const whiteView = (params) =>
  get("/web/finance/white/view/" + params.id, "");

// 白名单设置-修改
export const whiteUpdate = (params) => put("/web/finance/white/update", params);

// 白名单设置-状态-修改
export const whiteStateUpdate = (params) =>
  put("/web/finance/white/state/update", params);
